<template>
    <div>
        <h1>Employee Photos</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
        <button class="btn btn-default spacing_right pull-right" @click="removeHiddenClass">Show More</button>
    </div>
</template>

<script>

    import { store } from "@/store/Report.store";
    import Options from "./employee_photos_opt";
    import Results from "./utils/Results";

    export default {
        name: 'employee_photos',
        components: {Options, Results},
        data() {
            return {
                optionFields: {
                    cdiLocationId: {val: 0, qsField: '[numbers][cdi_location_id]'},
                    shift: {val: 0, qsField: '[numbers][s.user_staff_shift_id]'}
                },
                groupByFields: {},
                dataFields: {},
                visibleItems: 10
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            },
            removeHiddenClass() {
                // Get all elements with the .hidden class
                 const hiddenElements = document.querySelectorAll('.hidden');
                 // Remove the .hidden class from the first 10 elements
                 for (let i = 0; i < hiddenElements.length && i < 10; i++) {
                     hiddenElements[i].classList.remove('hidden');
                 }
            }
        },
        created() {
            this.$appStore.setTitle(['Employee Photos']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }

</script>
<style>
.hidden {
    display: none;
}
.employee_photo_box{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 2px 16px;
    color: black!important;
    text-align: center;
}

.employee_photo_box:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.employee_photo_box a{
    color: black!important;
    font-size: large;
}
</style>