<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" :key="id" :value="id">{{ location }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import cdiLocationCache from "@/cache/cdiLocation.cache.js";

    export default {
        name: 'Options',
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache
                }
            }
        }
    }
</script>